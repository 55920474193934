import Image from "next/image";
import styles from './Footer.module.css';
import { NavLink } from '../../ui';
import { IPageMenu } from "../../../interfaces";
import Address from "./Address";
import Menu from "./Menu";
import { getYear, pushRoute } from "@/lib/helpers/common";
import React from "react";

type props = {
    FooterData: IPageMenu[]
}
export default function FooterIN({ FooterData }: props) {
    return (
        <footer className={styles.footer}>
            <em className={styles.footerLeaf}><Image
                src='/assets/images/footer-tree.svg'
                alt="footer-tree"
                height={278}
                width={127}
            /></em>
            <div className="container">
                <div className={styles.assurance}>
                    <ul>
                        <li><em className={styles.tileLeaf}><Image
                            src='/assets/images/footer-leaf.svg'
                            alt=""
                            height={69}
                            width={51}
                        /></em><figure><Image
                            width={68}
                            height={49}
                            src="/assets/images/lowest-prices-guranted.svg"
                            alt="Lowest Price"
                            className="img-responsive"
                        /></figure><div><label>Lowest Price Guaranteed</label><span>No false claims. You get what you see. Lowest Price Guaranteed on home interiors.</span></div></li>
                        <li><em className={styles.tileLeaf}><Image
                            src='/assets/images/footer-leaf.svg'
                            alt=""
                            height={69}
                            width={51}
                        /></em><figure><Image
                            width={49}
                            height={46}
                            src="/assets/images/delivery-assurance.svg"
                            alt="Delivery Assurance"
                            className="img-responsive"
                        /></figure><div><label>Timely Delivery Assurance</label><span>Promised Timeline = Actual Date of Delivery. We strictly follow the timelines promised to you</span></div></li>
                        <li><em className={styles.tileLeaf}><Image
                            src='/assets/images/footer-leaf.svg'
                            alt=""
                            height={69}
                            width={51}
                        /></em><figure><Image
                            width={75}
                            height={75}
                            src="/assets/images/no-cost-emi-prcnt.svg"
                            alt="No Cost Emi"
                            className="img-responsive"
                        /></figure><div><label>No Cost EMI Option Available Upto 10 Lacs**</label><span>Get in touch with our designers to know more...</span></div></li>
                    </ul>
                </div>
                <div className={styles.otherResouresBx}>
                    <div className={styles.otherResoures}>
                        <figure>
                            <Image
                                width={110}
                                height={48}
                                src="/assets/images/interior-logo-white.svg"
                                alt="Interior Company"
                                className="img-responsive"
                            />
                        </figure>
                        <Menu FooterData={FooterData && FooterData.slice(0, 3)} />
                        <div className={styles.linkBoxes}></div>
                    </div>
                </div>
                <div className={styles.otherResoures}>
                    <Address />
                    <Menu FooterData={FooterData && FooterData.slice(3, 4)} />
                    <div className={styles.linkBoxes}>
                        <div className={styles.heading}>Keep in Touch</div>
                        <ul className={styles.keppInTouch}>
                            <li><NavLink href="https://www.instagram.com/interiorcompanyofficial/" target="_blank"><Image
                                width={20}
                                height={20}
                                src="/assets/images/instagram.svg"
                                alt="instagram"
                                className="img-responsive"
                            /></NavLink></li>
                            <li><NavLink href="https://www.facebook.com/InteriorCompanyOfficial" target="_blank"><Image
                                width={20}
                                height={20}
                                src="/assets/images/facebook.svg"
                                alt="facebook"
                                className="img-responsive"
                            /></NavLink></li>
                            <li><NavLink href="https://www.linkedin.com/company/interiorcompanyofficial/?viewAsMember=true" target="_blank"><Image
                                width={20}
                                height={20}
                                src="/assets/images/linkedin.svg"
                                alt="linkedin"
                                className="img-responsive"
                            /></NavLink></li>
                            <li><NavLink href="https://in.pinterest.com/interiorcompanyofficial/" target="_blank"><Image
                                width={20}
                                height={20}
                                src="/assets/images/pinterest.svg"
                                alt="pinterest"
                                className="img-responsive"
                            /></NavLink></li>
                            <li><NavLink href="https://www.youtube.com/@InteriorCompanyOfficial" target="_blank"><Image
                                width={25}
                                height={25}
                                src="/assets/images/youtube-icon-footer.svg"
                                alt="pinterest"
                                className="img-responsive"
                            /></NavLink></li>
                        </ul>
                    </div>
                </div>
                <div className={styles.otherResoures}>
                    <Menu FooterData={FooterData && FooterData.slice(4, 6)} />
                    <div className={styles.linkBoxes}></div>
                    <div className={styles.linkBoxes}></div>
                </div>
                <div className={styles.copyrightSectn}>
                    <div className={styles.cpyrightDtl}>&#169; {getYear()} www.interiorcompany.com. All rights reserved</div>
                </div>
            </div>
        </footer>
    );
}